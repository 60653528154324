import { AreaTable } from '@/common/types/area';
import { TABLE } from './share/constant';

export const getTableContainerSize = (table: AreaTable) => {
  const width = table.shape === 'rectangle' ? TABLE.RECTANGLE_SIZE : TABLE.SIZE;
  const height = TABLE.SIZE;

  if (!table.rotate) {
    return { width, height, containerWidth: width, containerHeight: height };
  }
  const radianAngle = (table.rotate * Math.PI) / 180; // Convert degree to radian
  const containerWidth =
    Math.abs(Math.cos(radianAngle)) * width +
    Math.abs(Math.sin(radianAngle)) * height;
  const containerHeight =
    Math.abs(Math.sin(radianAngle)) * width +
    Math.abs(Math.cos(radianAngle)) * height;
  return { containerWidth, containerHeight, width, height };
};
