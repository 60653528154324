import { Box, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useRef } from 'react';
import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
} from 'react-zoom-pan-pinch';
import useContainerSize from '../hooks/use-container-size';

interface GroundProps {
  children?: React.ReactNode;
  disableGridBackground?: boolean;
  groundControl?: React.ReactNode;
  isReservationView?: boolean;
  maxContainerHeight?: number;
  maxContainerWidth?: number;
  maxContentX: number;
  maxContentY: number;
  maxZoom?: number;
  minZoom?: number;
  onFitScreenScaleChange?: (scale: number) => void;
  onGroundClick?: () => void;
  onTransformed?: (scale: number) => void;
}

function Ground({
  children,
  disableGridBackground,
  groundControl,
  maxContainerHeight,
  maxContainerWidth,
  maxContentX,
  maxContentY,
  maxZoom,
  minZoom,
  onFitScreenScaleChange,
  onGroundClick,
  onTransformed,
}: GroundProps) {
  const theme = useTheme();
  const mouseDownMoment = useRef<number>(0);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const draggingRef = useRef<ReactZoomPanPinchRef>(null);

  let [containerWidth, containerHeight] = useContainerSize(containerRef);

  containerWidth =
    maxContainerWidth !== undefined
      ? Math.min(containerWidth, maxContainerWidth)
      : containerWidth;

  containerHeight =
    maxContainerHeight !== undefined
      ? Math.min(containerHeight, maxContainerHeight)
      : containerHeight;

  const groundWidth = useMemo(
    () => Math.round(Math.max(containerWidth || 0, maxContentX + 20 || 0)),
    [containerWidth, maxContentX]
  );

  const groundHeight = useMemo(
    () => Math.round(Math.max(containerHeight || 0, maxContentY + 20 || 0)),
    [containerHeight, maxContentY]
  );

  const fitScreenScale = useMemo(() => {
    if (groundWidth && groundHeight) {
      return Math.min(
        containerWidth / maxContentX,
        containerHeight / maxContentY,
        1
      );
    }
    return 0;
  }, [
    containerHeight,
    containerWidth,
    groundHeight,
    groundWidth,
    maxContentX,
    maxContentY,
  ]);

  useEffect(() => {
    if (fitScreenScale && onFitScreenScaleChange) {
      onFitScreenScaleChange(fitScreenScale);
    }
  }, [fitScreenScale, onFitScreenScaleChange]);

  return (
    <Box
      sx={{
        flex: 1,
        minWidth: 1,
        minHeight: 1,
        width: containerWidth,
        height: containerHeight,
        overflow: 'hidden',
      }}
      ref={containerRef}
    >
      <TransformWrapper
        minScale={minZoom}
        maxScale={maxZoom}
        ref={draggingRef}
        minPositionX={0}
        minPositionY={0}
        maxPositionX={groundWidth}
        maxPositionY={groundHeight}
        smooth
        onTransformed={(_, { scale }) => onTransformed?.(scale)}
      >
        {groundControl}
        <TransformComponent
          wrapperStyle={{
            maxWidth: '100%',
            maxHeight: '100%',
          }}
          contentStyle={{
            position: 'relative',
            background: disableGridBackground
              ? theme.palette.grey[100]
              : 'url("/images/background-floor-plan-editor.svg")',
            width: groundWidth,
            height: groundHeight,
            transition: 'width 0.3s, height 0.3s',
          }}
          wrapperProps={{
            onMouseDown: () => {
              mouseDownMoment.current = Date.now();
            },
            onMouseUp: () => {
              const moment = Date.now();
              if (moment - mouseDownMoment.current < 200 && !!onGroundClick) {
                onGroundClick();
              }
            },
          }}
        >
          {children}
        </TransformComponent>
      </TransformWrapper>
    </Box>
  );
}

export default Ground;
