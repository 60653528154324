import ResizeObserver from 'resize-observer-polyfill';
import { useEffect, useState } from 'react';

export default function useContainerSize(
  ref: React.MutableRefObject<HTMLElement | null>
): [number, number] {
  const [size, setSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (ref.current) {
      const observer = new ResizeObserver((entries) => {
        for (let entry of entries) {
          setSize({
            width: Math.round(entry.contentRect.width),
            height: Math.round(entry.contentRect.height),
          });
        }
      });

      observer.observe(ref.current);

      // Cleanup function to stop observing on component unmount
      return () => observer.disconnect();
    }
  }, [ref]);

  return [size.width, size.height];
}
