import { AreaTable } from '@/common/types/area';
import { useMemo, useRef } from 'react';
import { SxProps, Theme } from '@mui/material';
import { getTableContainerSize } from '../util';

export const useFloorPlanTable = (table: AreaTable) => {
  const {
    width: tableWidth,
    height: tableHeight,
    containerHeight,
    containerWidth,
  } = useMemo(() => getTableContainerSize(table), [table]);

  const tableBaseStyle: SxProps<Theme> = useMemo(() => {
    return {
      background: '#fff',
      border: '2px solid #383838 ',
      width: tableWidth,
      height: tableHeight,
      borderRadius: table.shape === 'circle' ? '50%' : 1,
      backgroundColor: 'white',
      transform: `rotate(${table.rotate}deg)`,
      transformOrigin: 'center center',
      cursor: 'move',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    };
  }, [table.rotate, table.shape, tableHeight, tableWidth]);

  const wrapperBaseStyle: SxProps<Theme> = useMemo(() => {
    return {
      width: containerWidth,
      height: containerHeight,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: table.shape === 'circle' ? '50%' : 1,
      flexDirection:
        table.rotate === 90 || table.rotate === 270 ? 'column' : 'row',
      cursor: 'auto',
      position: 'relative',
    };
  }, [containerHeight, containerWidth, table.rotate, table.shape]);

  const baseInnerStyle: SxProps<Theme> = useMemo(() => {
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transform: `rotate(-${table.rotate}deg)`,
      width: 'fit-content',
    };
  }, [table.rotate]);

  return {
    tableWidth,
    tableHeight,
    containerHeight,
    containerWidth,
    tableBaseStyle,
    wrapperBaseStyle,
    baseInnerStyle,
  };
};

export const useHandleTableClick = (onClick: () => void) => {
  const mouseDownMoment = useRef<number>(0);

  const onMouseDown = () => {
    mouseDownMoment.current = Date.now();
  };

  const onMouseUp = () => {
    const moment = Date.now();
    if (moment - mouseDownMoment.current < 200) {
      onClick();
    }
  };

  return { onMouseDown, onMouseUp };
};
