import { parse, add, format } from 'date-fns';

export interface OptionType {
  label: string;
  value: number;
}

export const generateOptions = (
  n: number,
  from: number = 1
): { label: string; value: number }[] => {
  const options: OptionType[] = [];
  let i = from;
  while (i <= n) {
    options.push({
      value: i,
      label: i.toString(),
    });
    i++;
  }
  return options;
};

export const generateBlockingTimeOptions = (
  from: string,
  to: string
): { label: string; value: string }[] => {
  const options: { label: string; value: string }[] = [];
  const endTime = parse(to, 'HH:mm:ss', new Date());
  let cursor = parse(from, 'HH:mm:ss', new Date());
  while (cursor <= endTime) {
    const value = format(cursor, 'HH:mm:ss');
    const label = format(cursor, 'HH:mm');
    options.push({ value, label });
    cursor = add(cursor, { minutes: 15 });
  }
  return options;
};
