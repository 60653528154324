export const GROUND = {
  MIN_SCALE: 0.2,
  MAX_SCALE: 6,
  GRID_SIZE: 20,
};

export const TABLE = {
  SIZE: 80,
  RECTANGLE_SIZE: 160,
  BORDER: 3,
  PADDING: 20,
};

export const DRAG_HANDLE_CLASS = 'drag_handler';
export const ROTATION_CHANGE_VALUE = 45;
