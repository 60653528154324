import { Area, AreaTable } from '@/common/types/area';
import { ReservationItem } from '@/common/types/reservation';
import { atom } from 'jotai';

export const areasAtom = atom<Area[]>([]);
export const tablesAtom = atom<AreaTable[]>([]);

export const selectedTableIdsAtom = atom<string[]>([]);
export const initialTablesAtom = atom<AreaTable[]>([]);
export const editingTableIdAtom = atom<string | null>(null);

export const reservationWithMenuAtom = atom<ReservationItem | null>(null);
export const reservationSelectSeatedAtom = atom<ReservationItem | null>(null);

export const editingTableAtom = atom<AreaTable | null>((get) => {
  const tables = get(tablesAtom);
  const id = get(editingTableIdAtom);
  return tables.find((t) => t.id === id) || null;
});
export const isDraggingAtom = atom<boolean>(false);

export const visibleTablesAtom = atom((get) => {
  const tables = get(tablesAtom);
  const editingTableId = get(editingTableIdAtom);
  const selectedTableIds = get(selectedTableIdsAtom);
  return tables.filter((it) => {
    const isEditingTable = it.id === editingTableId;
    const isSelectedTable = selectedTableIds.some((id) => id === it.id);
    return isEditingTable || isSelectedTable || (it.x && it.y);
  });
});

export const visibleAreasAtom = atom((get) => {
  const areas = get(areasAtom);
  const visibleTables = get(visibleTablesAtom);
  return areas.filter((a) =>
    a.tables?.some(
      (t) => (a.x && a.y) || visibleTables.some((vt) => vt.id === t.id)
    )
  );
});

export const setEditingTableIdAtom = atom(null, (get, set, id: string) => {
  set(editingTableIdAtom, id);
  set(selectedTableIdsAtom, []);
});

export const setSelectedTablesAtom = atom(
  null,
  (get, set, tableIds: string[]) => {
    set(selectedTableIdsAtom, tableIds);
    set(editingTableIdAtom, null);
  }
);
